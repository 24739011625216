<template>
  <div class="WhycliniccarePage">
    <header class="w-full px-[30px] md:px-[80px] pt-[130px] pb-[80px] text-white relative after:absolute after:top-0 after:left-0 after:w-full after:h-full after:bg-[rgba(0,0,0,0.73)]">
      <img :src="require('@/image/2.jpg')" class="absolute top-0 left-0 w-full h-full object-cover" alt="">
      <div class="z-[2] relative text-center">
        <h1 class="text-[10vw] md:text-[50px] font-[700] mb-[20px]">المدونة</h1>
      </div>
    </header>
    <div class="WhycliniccarePage max-w-[1400px] px-[40px] md:px-[60px] py-[60px] text-center w-full m-auto grid  md:grid-cols-2 gap-[40px] p-[20px]">
      <div v-for="(blog, index) in blogs" :key="index" class="flex justify-start items-center gap-[40px] rounded-[10px] overflow-hidden relative after:absolute after:top-0 after:left-0 after:w-full after:h-full after:bg-[rgba(0,0,0,0.55)] p-[40px_20px]">
          <img :src="`${url}/${blog.image}`" class="rounded-lg absolute top-0 left-0 w-full h-full object-cover" alt="">
          <div class="relative z-[3] text-start sm:px-[30px]">
            <h1 class="text-[38px] text-[#ffffff]  mb-[20px]">{{ blog.title }}</h1>
            <button @click="$router.push(`/blog?q=${blog.id}`)" class="bg-[linear-gradient(180deg,_#4F94FC_0%,_#035FE9_100%)] hover:bg-[linear-gradient(180deg,#2C68C1_0%,#04347B_100%)] transition-all duration-[.5s] text-[16px] text-[white] py-[15px] px-[20px] rounded-md cursor-pointer">اضغط هنا لقراءة المزيد</button>
          </div>
        </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios';

export default {
  name: 'WhycliniccarePage',
  data() {
    return {
      blogs: [],
      url: process.env.VUE_APP_URL,
    };
  },
  created(){
    axios.get(`${process.env.VUE_APP_URL}/api/get_blogs`)
    .then((response) => { 
        this.blogs = response.data.blogs 
    }).catch(function (error) {
        console.log(error.message)
    });
  },
}
</script>

<style scoped lang="scss">

</style> 