<template>
  <div class="ConductingexaminationsPage">
    <div class="ConductingexaminationsPage text-center w-full max-w-[1400px] m-auto px-[10px] md:px-[60px] py-[60px] relative">
      <h1 class="absolute top-0 left-0 text-[green] font-bold text-[18px] mb-[20px]">{{ prices[2].price }} ريال</h1>
      <ValidationObserver v-slot="{ handleSubmit }"  v-show="details_form == false">
        <h1 class="text-xl font-bold leading-tight tracking-tight text-center text-gray-900 md:text-[28px] mb-[20px]">نموذج طلب إجراء الفحوصات والأشعة</h1>
      <p class="text-[14px] text-gray-700 mb-[30px]">أحياناً يتطلب الحصول على رأي طبي ثاني إجراء المزيد من الفحوصات والأشعة والتحاليل المختبرية التي تساعد الاستشاري على إعطاء تقرير مفصل عن الحالة الصحية للمريض وتقديم خطط العلاج المقترحة التي تعمل على تحسين الظروف الصحية للمريض.</p>
        <form  @submit.prevent="handleSubmit(details_form1)" class="space-y-4 md:space-y-6 px-[10px] md:px-[30px] pt-[40px] text-start" action="#">
           <ValidationProvider  name="notes" rules="required" :custom-messages="{required: 'اكتب  الحالة الصحية باختصار !'}" v-slot="{ errors }">
              <div>
                <label for="notes" class="block mb-2 text-sm font-medium text-gray-900 ">الحالة الصحية باختصار</label>
                <input type="text" v-model="notes_clint" name="notes" id="notes" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 " placeholder="" required="">
                <span class="text-[red] font-bold text-[12px] m-[5px] my-[20px] block">{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
            <h1 class="block mb-[5px] text-sm font-medium text-gray-900 ">حدد الشعة المطلوب إجراؤها من القائمة التالية: </h1>
            <div class="w-full flex flex-wrap justify-start items-center gap-[20px]">
              <div v-for="(test_array, index) in tests_array" :key="index" class="flex items-center">
                  <input type="checkbox" :id="test_array" :value="test_array" v-model="tests" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500">
                  <label :for="test_array" class="ms-2 text-sm font-medium text-gray-900">{{test_array}}</label>
              </div>
            </div>
            <div>
              <label for="test_notes" class="block mb-2 text-sm font-medium text-gray-900 ">حول طبيعة الفحص المطلوب</label>
              <input type="text" v-model="test_notes" name="test_notes" id="test_notes" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 " placeholder="">
            </div>
            <h1 class="block mb-[5px] text-sm font-medium text-gray-900 "> حدد الفحوصات المختبرية المطلوب إجراؤها من القائمة التالية:</h1>
            <div class="w-full flex flex-wrap justify-start items-center gap-[20px]">
              <div  v-for="(labTest_array, index) in labTests_array" :key="index" class="flex items-center">
                  <input type="checkbox" :id="labTest_array" :value="labTest_array" v-model="labTests" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500">
                  <label :for="labTest_array" class="ms-2 text-sm font-medium text-gray-900">{{labTest_array}}</label>
              </div>
            </div>
            <div>
              <label for="labTests_notes" class="block mb-2 text-sm font-medium text-gray-900 ">حول طبيعة الفحص المطلوب</label>
              <input type="text" v-model="labTests_notes" name="labTests_notes" id="labTests_notes" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 " placeholder="" >
            </div>
            <div class=" relative w-full mb-[30px]">
              <span ref='error_hospitall' class="hidden text-[red] font-bold text-[12px] m-[5px] my-[20px] block">اختر المستشفي</span>
              <div class="relative w-full">
                <div ref="mytoogle_hospitals" class="cursor-pointer flex items-center justify-between border p-4 py-[10px] text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-primary-500 focus:border-primary-500 w-full" @click="toogle_hospitals == false? toogle_hospitals = true : toogle_hospitals = false"><p class="pr-[35px] md:pr-[20px] capitalize">{{select_hospitals}}</p> <img :src="require('@/image/angle-down.png')" class="w-[21px]" :class="{'-rotate-90': toogle_hospitals}"></div>
                <ul class="absolute top-[103%] left-0 z-50 bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full" :class="{'block': toogle_hospitals, hidden: !toogle_hospitals}">
                  <li v-for="(hospitals, index) in hospitalss" :key="index" @click="selectOF_hospitals(hospitals.name, hospitals.id)" class="item_select capitalize cursor-pointer py-[12px] px-[18px] text-[14px] flex items-center hover:bg-[#eeeeee] hover:text-black">{{ hospitals.name + '   - ' +  hospitals.address}} <span class="press">اختر</span></li>
                </ul>
              </div>
            </div>
            <ValidationProvider name="terms" rules="required|" :custom-messages="{required: 'اكتب كلمة السر !'}" >
              <div class="flex items-start mt-[20px]">
                <div class="flex items-center h-5">
                  <input id="terms" aria-describedby="terms" v-model="terms" type="checkbox" class="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800" required="">
                </div>
                <div class="ml-3 text-sm">
                  <label for="terms" class="font-light text-gray-500 dark:text-gray-300 border-[blue]"> الضغط هنا يعني الموافقة على  <router-link to="/privacy" class="font-medium text-[#0000ffbd] hover:underline dark:text-primary-700">الخصوصية</router-link> و <router-link to="terms" class="font-medium text-[#0000ffbd] hover:underline dark:text-primary-700" href="#">اتفاقية الاستخدام</router-link></label>
                </div>
              </div>
            </ValidationProvider>
            <button type="submit" class="w-full text-white bg-[#5599f9] hover:bg-[#4b89e1] focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">إرسال</button>
        </form>
    </ValidationObserver>
    <ValidationObserver v-slot="{ handleSubmit }" v-if="details_form == true">
      <h1 class="text-xl font-bold leading-tight tracking-tight text-center text-gray-900 md:text-[28px] mb-[20px]">الدفع</h1>
      <p class="text-[14px] text-gray-700 mb-[30px]"> نرجوا تحويل قيمة الاستشارة للحساب البنكي التالي، ثم إرفاق صورة من التحويل لعتماد الطلب</p>
        <div class="w-full text-start p-[30px_30px]">
          <h1 class="text-[18px] font-[700] mb-[20px] text-[#272727]" v-for="(bank, index) in banks" :key="index">عن طريق {{bank.bank}} : <b>{{ bank.number }}</b></h1>
        </div>
        <form  @submit.prevent="handleSubmit(onSubmit)" class="space-y-4 md:space-y-6 px-[10px] md:px-[30px] pt-[20px] text-start" action="#">
            <ValidationProvider rules="required" ref="provider2" name="upload_file" :custom-messages="{required: ' ارفع التحويل !'}" v-slot="{ errors }">
              <div class="w-full md:w-[100%] relative mt-[10px]">
                  <div id="conn-input-file" class="w-full ">
                    <span> {{ upload_file_clint_transfers == false? 'إرفاق التحويل البنكي' : upload_file_clint_transfers.name}}</span>
                    <input type="file" ref="input_file2" @change="handleFileChange2" name="upload_file" id="add-music-file" accept="image/*,.pdf">
                    <span class="text-[red] font-bold text-[12px] m-[5px] my-[20px] block">{{ errors[0] }}</span>
                  </div>
              </div>
            </ValidationProvider>
            <button type="submit" :disabled='isDisabled' class="w-full text-white bg-[#5599f9] hover:bg-[#4b89e1] focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">اعتماد الطلب</button>
        </form>
    </ValidationObserver>
    </div>
  </div>
</template>

<script>

import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full';
import axios from 'axios';

export default {
  name: 'ConductingexaminationsPage',
  data() {
    return {
      upload_file_clint_transfers: false,

      details_form: false,

      isDisabled: false,

      notes_clint: '',
      terms:'',

      tests: [],
      test_notes: '',

      labTests: [],
      labTests_notes: '',

      tests_array: [
        "الرنين المغناطيسي",
        "الأشعة المقطعية",
        "الأشعة السينية",
        "الموجات فوق الصوتية",
        "تخطيط القلب",
        "تصوير الأوعية الدموية",
        "فحوصات أخرى"
      ],

      labTests_array: [
        "فحص الدم",
        "فحص البول",
        "فحص الغدة الدرقية",
        "فحص الكوليسترول",
        "فحوصات مختبرية أخرى"
      ],

      prices: [
        {price: 0},
        {price: 0},
        {price: 0}
      ],
      banks: '',

      hospitalss: [],
      select_hospitals: 'المستشفيات والمراكز الطبية  ',
      hospitalss_id: false,
      toogle_hospitals : false,
    };
  },
  created(){
    axios.get(`${process.env.VUE_APP_URL}/api/get_prices`)
    .then((response) => { 
        this.prices = response.data.prices;
        console.log(this.prices)
    }).catch(function (error) {
        console.log(error.message)
    });
    axios.get(`${process.env.VUE_APP_URL}/api/get_banks`)
    .then((response) => { 
        this.banks = response.data.numbers;
        console.log(this.banks)
    }).catch(function (error) {
        console.log(error.message)
    });
    axios.get(`${process.env.VUE_APP_URL}/api/reservation/create`)
    .then((response) => { 
      console.log(response.data)
        response.data.hospitals.forEach((value) => {
            this.hospitalss.push(value);
        });
    }).catch(function (error) {
        console.log(error.message)
    });
  },
  components:{
    ValidationProvider,
    ValidationObserver
  },
  mounted() {
  let self = this;
  document.addEventListener('click', (e)=> {
      if (self.$refs.mytoogle_hospitals !==undefined && self.$refs.mytoogle_hospitals.contains(e.target)===false) {
        self.toogle_hospitals = false;
      }
  })
  },
  methods: {
    details_form1 () {
      if(this.hospitalss_id !== false){
        this.$refs.error_hospitall.classList.remove('block')
        this.$refs.error_hospitall.classList.add('hidden')

      this.details_form = true;

      }else{
        this.$refs.error_hospitall.classList.remove('hidden')
        this.$refs.error_hospitall.classList.add('block')
      }
    },
    onSubmit(){
      this.isDisabled = true
        const addData = {
            hospital_id: this.hospitalss_id,
            notes: this.notes_clint,  
            rays: this.tests,
            rays_notes: this.test_notes,
            analysis: this.labTests,
            analysis_notes: this.labTests_notes,
            transaction: this.upload_file_clint_transfers,
        }     
        const headers = { 
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${this.$localStorage.token}`
        };
    
        axios.post(`${process.env.VUE_APP_URL}/api/reservation/store`, addData, { headers })
        .then(()=>{
          this.$toast.success(" تم استالم الطلب وجاري اتخاذالازم،وستصلكم رسالة عند اعتمادالطلب. شكرا لثقتكم باملنصةونتمنى لكم الشفاءالعاجل", {
            position: "top-right",
            timeout: 1000,
            pauseOnFocusLoss: true,
            hideProgressBar: true,
            icon: false,
            rtl: true
          });
              this.$router.push('/My-all-servicesPage');
        });
    },
     selectOF_hospitals(x,y){
      this.select_hospitals = x;
      this.hospitalss_id = y
     },
     async handleFileChange(event) {
      const file = event.target.files;
      this.upload_file_clint = file
    },
    async handleFileChange2(e) {
      const { valid } = await this.$refs.provider2.validate(e);
      if (valid) {
        this.upload_file_clint_transfers = this.$refs.input_file2.files[0]
      }else{
        this.upload_file_clint_transfers = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
 .item_select{
  position: relative;

  .press{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 20px;
    font-size: 12px;
    color: rgb(168, 168, 168);
    display: none;
  }
  &:hover{
    .press{
      display: block !important;
    }
  }
}

#conn-input-file {
  width: 100%;
  background: linear-gradient(90deg, rgb(2, 0, 36) 0%, #a5003dbf 100%, rgba(9, 9, 121, 0.2973564426) 100%);
  color: white;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  border-radius: 4px;
  transition: .5s;

  &:hover{
    background: linear-gradient(90deg, rgb(2, 0, 36) 0%, #410018bf 100%, rgba(9, 9, 121, 0.2973564426) 100%);
  }

  input {
      width: 100%;
      height: 100%;
      opacity: 0;
      z-index: 999999;
      position: absolute;
      top: 0;
      left: 0;
      cursor: pointer;
  }
}

</style> 