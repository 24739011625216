-<template>
  <div class="w-full">
    <section class="bg-gray-50 py-[170px] mt-[50px]">
      <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto lg:py-0">
          <div class="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-lg xl:p-0">
              <div class="space-y-4 md:space-y-6 py-[40px] pt-[0]">
                <div v-if="masssage_send_email == true">
                  <h1 class="text-xl font-bold leading-tight tracking-tight text-center text-gray-900 md:text-2xl py-[30px]">تاكد من البريد الالكتروني الخاص بك</h1>
                </div>
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form  @submit.prevent="handleSubmit(change_pass)" class="space-y-4 md:space-y-6 px-[10px] md:px-[30px] pt-[40px]" action="#" v-if="masssage_send_email == false">
                      <p class="text-[14px] font-bold leading-tight tracking-tight text-center text-gray-400 mb-[30px]">تاكد من بريدك الالكتروني بعد الضغط علي ارسال</p>
                      <h1 ref="error_login" class="hidden text-[14px] font-bold leading-tight tracking-tight text-center text-[red] mb-[30px]">اكتب البيانات بشكل صحيح !!</h1>
                        <ValidationProvider name="Name" rules="required|min:8" :custom-messages="{required: 'اكتب كلمة السر !', min: 'لا تقل عن 8 احروف '}" v-slot="{ errors }">
                          <label for="password" class="block mb-2 text-sm font-medium text-gray-900 ">باسورد جديد</label>
                          <input type="password" v-model="password_clint" name="password" id="password" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 " placeholder="" required="">
                          <span class="text-[red] font-bold text-[12px] m-[5px] my-[20px] block">{{ errors[0] }}</span>
                        </ValidationProvider>
                        <button type="submit" class="w-full text-white bg-[#5599f9] hover:bg-[#4b89e1] focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">ارسال</button>
                    </form>
                </ValidationObserver>
              </div>
          </div>
      </div>
    </section>
  </div>
</template>

<script>

// import { extend } from 'vee-validate';
import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full';
import axios from 'axios';

export default {
  name: 'ChangeforgetpassPage',
  data() {
    return {
      masssage_send_email: false,
      password_clint: '',
    };
  },
  components:{
    ValidationProvider,
    ValidationObserver
  },
  mounted(){
    if(this.$localStorage.token){
      this.$router.push('/')
    }
  },  
  methods: {
    change_pass () {
      const addData = {
          password: this.password_clint,
      }       
      axios.post(`${process.env.VUE_APP_URL}/api/update_password/${this.$route.params.id}`, addData)
      .then((response)=>{
        if(response){
          this.$router.push('/login')
        }
        }).catch(err => {
            console.log(err)
            this.$refs.error_login.classList.remove('hidden')
            this.$refs.error_login.classList.add('block')
            // localStorage.setItem("activeUser", false);
        })
    },
  }
}
</script>

<style  lang="scss">

</style>
