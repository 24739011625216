<template>
  <div class="SecondopinionPage">
    <div class="SecondopinionPage text-center w-full max-w-[1400px] m-auto px-[10px] md:px-[60px] py-[60px] relative">
      <h1 class="absolute top-0 left-0 text-[green] font-bold text-[18px] mb-[20px]">{{ prices[0].price }} ريال</h1>
      <ValidationObserver v-slot="{ handleSubmit }"  v-show="details_form == false">
        <h1 class="text-xl font-bold leading-tight tracking-tight text-center text-gray-900 md:text-[28px] mb-[20px]">الرأي الطبي الثاني</h1>
      <p class="text-[14px] text-gray-700 mb-[30px]">توفر منصة كلينيكير خدمة الرأي الطبي الثاني لتقييم الحالة الصحية وتقديم رأي طبي آخر وفقاً لطبيعة الحالة الصحية للمريض والتقارير المتوفرة والفحوصات التي تم إجراؤها</p>
        <form  @submit.prevent="handleSubmit(details_form1)" class="space-y-4 md:space-y-6 px-[10px] md:px-[30px] pt-[40px] text-start" action="#">
            <div class=" relative w-full">
              <div class="relative w-full">
                <label for="title" class="block mb-2 text-sm font-medium text-gray-900 ">التخصص الطبي</label>
                <div ref="mytoogle_specialties" class="mb-[20px] cursor-pointer flex items-center justify-between border p-4 py-[10px] text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-primary-500 focus:border-primary-500 w-full" @click="toogle_specialties == false? toogle_specialties = true : toogle_specialties = false"><p class="pr-[35px] md:pr-[20px] capitalize">{{select_specialties}}</p> <img :src="require('@/image/angle-down.png')" class="w-[21px]" :class="{'-rotate-90': toogle_specialties}"></div>
                <ul class="absolute top-[103%] left-0 z-50 bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full" :class="{'block': toogle_specialties, hidden: !toogle_specialties}">
                  <li v-for="(specialties, index) in specialtiess" :key="specialties" @click="selectOF_specialties(specialties, index)" class="item_select capitalize cursor-pointer py-[12px] px-[18px] text-[14px] flex items-center hover:bg-[#eeeeee] hover:text-black">{{specialties}} <span class="press">اختر</span></li>
                </ul>
              </div>
            </div>
            <ValidationProvider name="details_problem" rules="required" :custom-messages="{required: 'اكتب  تفاصيل المشكلة الطبية !'}" v-slot="{ errors }">
              <div class="my-[20px]">
                <label for="title" class="block mb-2 text-sm font-medium text-gray-900 ">تفاصيل المشكلة الطبية</label>
                <input type="text" v-model="details_problem_clint" name="details_problem" id="details_problem" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "  placeholder="اشرح مشكلتك الصحية" required="">
                <span class="text-[red] font-bold text-[12px] m-[5px] my-[20px] block">{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
            <ValidationProvider name="dangerous_diseases_var" rules="required" :custom-messages="{required: 'جاوب هل ديك مرض ام لا !'}" v-slot="{ errors }">
              <h1 class="block mb-2 text-sm font-medium text-gray-900 ">هل لديك تاريخ عائلي قوي لأي أمراض خطيرة؟ </h1>
              <span class="text-[red] font-bold text-[12px] m-[5px] my-[20px] block">{{ errors[0] }}</span>
              <div class="w-full flex flex-wrap justify-start items-center gap-[20px]">
                <div class="flex items-center">
                    <input id="dangerous_diseases1" v-model="dangerous_diseases" type="radio" value="نعم" name="dangerous_diseases" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500">
                    <label for="dangerous_diseases1" class="ms-2 text-sm font-medium text-gray-900">نعم</label>
                </div>
                <div class="flex items-center">
                    <input id="dangerous_diseases2" v-model="dangerous_diseases" type="radio" value="لا" name="dangerous_diseases" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 ">
                    <label for="dangerous_diseases2" class="ms-2 text-sm font-medium text-gray-900">لا</label>
                </div>
                <div class="flex items-center">
                    <input id="dangerous_diseases3" v-model="dangerous_diseases" type="radio" value="لست متأكدا" name="dangerous_diseases" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 ">
                    <label for="dangerous_diseases3" class="ms-2 text-sm font-medium text-gray-900">لست متاكد</label>
                </div>
              </div>
            </ValidationProvider>
            <ValidationProvider name="hospitalized" rules="required" :custom-messages="{required: 'جاوب هل نعم ام لا !'}" v-slot="{ errors }">
              <h1 class="block mb-2 text-sm font-medium text-gray-900 mt-[30px]">هل سبق وتم تنويمك في المستشفى؟ </h1>
              <span class="text-[red] font-bold text-[12px] m-[5px] my-[20px] block">{{ errors[0] }}</span>
              <div class="w-full flex flex-wrap justify-start items-center gap-[20px]">
                <div class="flex items-center">
                    <input id="hospitalized1" v-model="hospitalized" type="radio" value="نعم" name="hospitalized" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500">
                    <label for="hospitalized1" class="ms-2 text-sm font-medium text-gray-900">نعم</label>
                </div>
                <div class="flex items-center">
                    <input id="hospitalized2" v-model="hospitalized" type="radio" value="لا" name="hospitalized" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 ">
                    <label for="hospitalized2" class="ms-2 text-sm font-medium text-gray-900">لا</label>
                </div>
              </div>
            </ValidationProvider>
            <ValidationProvider name="surgery" rules="required" :custom-messages="{required: 'جاوب هل نعم ام لا !'}" v-slot="{ errors }">
              <h1 class="block mb-2 text-sm font-medium text-gray-900 mt-[30px]">هل أجريت عمليات جراحية من قبل؟</h1>
              <span class="text-[red] font-bold text-[12px] m-[5px] my-[20px] block">{{ errors[0] }}</span>
              <div class="w-full flex flex-wrap justify-start items-center gap-[20px]">
                <div class="flex items-center">
                    <input id="surgery1" v-model="surgery" type="radio" value="نعم" name="surgery" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500">
                    <label for="surgery1" class="ms-2 text-sm font-medium text-gray-900">نعم</label>
                </div>
                <div class="flex items-center">
                    <input id="surgery2" v-model="surgery" type="radio" value="لا" name="surgery" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 ">
                    <label for="surgery2" class="ms-2 text-sm font-medium text-gray-900">لا</label>
                </div>
              </div>
            </ValidationProvider>
            <h1 class="block mb-[5px] text-sm font-medium text-gray-900 ">الفحوصات التي تم إجراؤها</h1>
              <div class="w-full flex flex-wrap justify-start items-center gap-[20px]">
                <div v-for="(test_array, index) in tests_array" :key="index" class="flex items-center">
                    <input type="checkbox" :id="test_array" :value="test_array" v-model="tests" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500">
                    <label :for="test_array" class="ms-2 text-sm font-medium text-gray-900">{{ test_array }}</label>
                </div>
             </div>
             <h1 class="block mb-[5px] text-sm font-medium text-gray-900 ">حدّد الأمراض التي تعاني منها</h1>
              <div class="w-full flex flex-wrap justify-start items-center gap-[20px]">
                <div  v-for="(disease_array, index) in diseases_array" :key="index" class="flex items-center">
                    <input type="checkbox" :id="disease_array" :value="disease_array" v-model="diseases" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500">
                    <label :for="disease_array" class="ms-2 text-sm font-medium text-gray-900">{{disease_array}}</label>
                </div>
             </div>
              <div class="w-full md:w-[100%] relative mt-[30px]">
                  <div id="conn-input-file" class="w-full p-[20px]">
                    <div class="flex flex-col gap-[10px] justify-center items-start">
                      <span v-for="(upload_file, index) in upload_file_clint" :key="index"> {{ index + ' - '+ upload_file.name}}</span>
                      <span v-if="upload_file_clint.length == 0">تحميل التقارير الطبية والفحوصات</span>
                    </div>
                    <input type="file" ref="input_file" @change="handleFileChange" name="upload_file" id="add-music-file" multiple accept="image/*,.pdf" >
                  </div>
              </div>
            <ValidationProvider name="terms" rules="required|" :custom-messages="{required: 'اكتب كلمة السر !'}" >
              <div class="flex items-start mt-[20px]">
                <div class="flex items-center h-5">
                  <input id="terms" aria-describedby="terms" v-model="terms" type="checkbox" class="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800" required="">
                </div>
                <div class="ml-3 text-sm">
                  <label for="terms" class="font-light text-gray-500 dark:text-gray-300 border-[blue]"> الضغط هنا يعني الموافقة على  <router-link to="/privacy" class="font-medium text-[#0000ffbd] hover:underline dark:text-primary-700">الخصوصية</router-link> و <router-link to="terms" class="font-medium text-[#0000ffbd] hover:underline dark:text-primary-700" href="#">اتفاقية الاستخدام</router-link></label>
                </div>
              </div>
            </ValidationProvider>
            <button type="submit" class="w-full text-white bg-[#5599f9] hover:bg-[#4b89e1] focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">إرسال</button>
        </form>
    </ValidationObserver>
    <ValidationObserver v-slot="{ handleSubmit }" v-if="details_form == true">
      <h1 class="text-xl font-bold leading-tight tracking-tight text-center text-gray-900 md:text-[28px] mb-[20px]">الدفع</h1>
      <p class="text-[14px] text-gray-700 mb-[30px]"> نرجوا تحويل قيمة الاستشارة للحساب البنكي التالي، ثم إرفاق صورة من التحويل لعتماد الطلب</p>
        <div class="w-full text-start p-[50px_30px]">
          <h1 class="text-[18px] font-[700] mb-[20px] text-[#272727]" v-for="(bank, index) in banks" :key="index">عن طريق {{bank.bank}} : <b>{{ bank.number }}</b></h1>
        </div>
        <form  @submit.prevent="handleSubmit(onSubmit)" class="space-y-4 md:space-y-6 px-[10px] md:px-[30px] pt-[40px] text-start" action="#">
            <ValidationProvider rules="required" ref="provider2" name="upload_file" :custom-messages="{required: ' ارفع التحويل !'}" v-slot="{ errors }">
              <div class="w-full md:w-[100%] relative mt-[30px]">
                  <div id="conn-input-file" class="w-full ">
                    <span> {{ upload_file_clint_transfers == false? 'إرفاق التحويل البنكي' : upload_file_clint_transfers.name}}</span>
                    <input type="file" ref="input_file2" @change="handleFileChange2" name="upload_file" id="add-music-file" accept="image/*,.pdf">
                    <span class="text-[red] font-bold text-[12px] m-[5px] my-[20px] block">{{ errors[0] }}</span>
                  </div>
              </div>
            </ValidationProvider>
            <button type="submit" :disabled='isDisabled' class="w-full text-white bg-[#5599f9] hover:bg-[#4b89e1] focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">اعتماد الطلب</button>
        </form>
    </ValidationObserver>
    </div>
  </div>
</template>

<script>

import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full';
import axios from 'axios';

export default {
  name: 'SecondopinionPage',
  data() {
    return {
      title_clint: '',
      details_problem_clint: '',
      dangerous_diseases : '',
      hospitalized: '',
      surgery: '',
      tests: [],
      diseases: [],
      notes_clint: '',
      files: [],
      upload_file_clint: [],
      terms:'',

      tests_array:[
        "الاشعة المقطعية ",
        "أشعة السينية",
        "تخطيط القلب",
        "موجات فوق الصوتية",
        "تصوير الأوعية الدموية",
        "الرنين المغناطيسي",
        "تحاليل مختبرية",
        "فحوصات أخرى"
      ],

      diseases_array:[
        "اضطراب نبضات القلب",
        "أمراض القلب والشرايين",
        "داء السكري",
        "ارتفاع ضغط الدم",
        "السرطان",
        "الجلطات الدموية",
        "السكتة الدماغية",
        "التهاب الشعب الهوائية",
        "أمراض الكلى",
        "التهاب الكبد",
        "التهاب المفاصل",
        "الربو",
        "القلق والكتئاب",
        "أمراض الجهاز الهضمي",
        "أمراض أخرى"
      ],

      prices: [
        {price: 0},
        {price: 0},
        {price: 0}
      ],
      banks: '',

      isDisabled: false,

      upload_file_clint_transfers: false,

      details_form: false,

      specialtiess: [],
      select_specialties: '',
      toogle_specialties : false,
    };
  },
  created(){
    axios.get(`${process.env.VUE_APP_URL}/api/get_prices`)
    .then((response) => { 
        this.prices = response.data.prices;
        console.log(this.prices)
    }).catch(function (error) {
        console.log(error.message)
    });
    axios.get(`${process.env.VUE_APP_URL}/api/get_banks`)
    .then((response) => { 
        this.banks = response.data.numbers;
        console.log(this.banks)
    }).catch(function (error) {
        console.log(error.message)
    });
    console.log(this.upload_file_clint.length)
    axios.get(`${process.env.VUE_APP_URL}/api/report/create`)
    .then((response) => { 
        response.data.professions.forEach((value) => {
            this.specialtiess.push(value.name);
        });
        this.select_specialties = response.data.professions[0].name
    }).catch(function (error) {
        console.log(error.message)
    });
  },
  components:{
    ValidationProvider,
    ValidationObserver
  },
  mounted() {
  let self = this;
  document.addEventListener('click', (e)=> {
      if (self.$refs.mytoogle_specialties !==undefined && self.$refs.mytoogle_specialties.contains(e.target)===false) {
        self.toogle_specialties = false;
      }
  })
  },
  methods: {
    details_form1 () {
      this.details_form = true;
    },
    onSubmit(){
      this.isDisabled = true
      const addData = {
          profession: this.select_specialties,
          desc:  this.details_problem_clint,
          family_related: this.dangerous_diseases,
          sleep_on_hospital: this.hospitalized,
          surgery: this.surgery,  
          tests: this.tests,
          diseas: this.diseases,
          transaction: this.upload_file_clint_transfers,
          file: this.upload_file_clint,
      }     
      const headers = { 
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${this.$localStorage.token}`
       };
  
      axios.post(`${process.env.VUE_APP_URL}/api/report/store`, addData, { headers })
      .then(()=>{
          this.$toast.success(" تم استالم الطلب وجاري اتخاذالازم،وستصلكم رسالة عند اعتمادالطلب. شكرا لثقتكم باملنصةونتمنى لكم الشفاءالعاجل", {
            position: "top-right",
            timeout: 1000,
            pauseOnFocusLoss: true,
            hideProgressBar: true,
            icon: false,
            rtl: true
          });
            this.$router.push('/My-all-servicesPage');
      })
    },
     selectOF_specialties(x){
      this.select_specialties = x;
     },
     async handleFileChange(event) {
      const file = event.target.files;
      this.upload_file_clint = file
    },
    async handleFileChange2(e) {
      const { valid } = await this.$refs.provider2.validate(e);
      if (valid) {
        this.upload_file_clint_transfers = this.$refs.input_file2.files[0]
      }else{
        this.upload_file_clint_transfers = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
 .item_select{
  position: relative;

  .press{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 20px;
    font-size: 12px;
    color: rgb(168, 168, 168);
    display: none;
  }
  &:hover{
    .press{
      display: block !important;
    }
  }
}

#conn-input-file {
  width: 100%;
  background: linear-gradient(90deg, rgb(2, 0, 36) 0%, #a5003dbf 100%, rgba(9, 9, 121, 0.2973564426) 100%);
  color: white;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  border-radius: 4px;
  transition: .5s;

  &:hover{
    background: linear-gradient(90deg, rgb(2, 0, 36) 0%, #410018bf 100%, rgba(9, 9, 121, 0.2973564426) 100%);
  }

  input {
      width: 100%;
      height: 100%;
      opacity: 0;
      z-index: 999999;
      position: absolute;
      top: 0;
      left: 0;
      cursor: pointer;
  }
}

</style> 