<template>
  <div id="app">
    <NavPage />
    <router-view></router-view>
    <FooterPage />
    <a href="https://api.whatsapp.com/send/?phone=966502925080&text&type=phone_number&app_absent=0" class="z-[2] fixed bottom-[30px] left-[30px] p-[10px] w-[50px] h-[50px] md:w-[70px] md:h-[70px]  flex justify-center items-center rounded-full shadow-[1px_6px_24px_0_rgba(7,94,84,.24)] bg-[#25d366] hover:bg-[#128c7e] transition ease-in">
        <i class="fa-brands fa-whatsapp text-white text-[30px] md:text-[40px]"></i>
    </a>
  </div>
</template>

<script>

import NavPage from './components/NavPage.vue';
import FooterPage from './components/FooterPage.vue';

import Vue from 'vue';
export default {
  name: 'App',

  components: {
    NavPage,
    FooterPage,
  },
  created(){
    Vue.prototype.$localStorage = new Vue({
    data: { 
      token: window.localStorage.getItem('token'),
      activeUser: window.localStorage.getItem('activeUser'),
      person_details_user: window.localStorage.getItem('person_details_user') 
    },
    
    watch:{ 
      token(value){ 
        window.localStorage.setItem('token', value)
      },
      activeUser(value){ 
        window.localStorage.setItem('activeUser', value)
      },
      person_details_user(value){ 
        window.localStorage.setItem('person_details_user', value)
      } 
    },
    })
  },
}
</script>

<style>
@import "~vue-wysiwyg/dist/vueWysiwyg.css";
 

</style>