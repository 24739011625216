-<template>
  <div class="z-[5] w-full fixed top-0 left-0 transition-all duration-[.6s] bg-[#00000005]">
    <div class="min-h-screen bg-gray-900 flex flex-col items-center justify-center">
      <h1 class="text-5xl text-white font-bold mb-8 animate-pulse">
          Coming Soon
      </h1>
      <p class="text-white text-lg mb-8">
          We're working hard to bring you something amazing. Stay tuned!
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotfoundPage',
}
</script>

<style scoped lang="scss">
.router-link-exact-active{
  color: #5599f9;
  opacity: 1;
}
</style>
