-<template>
  <div class="w-full">
    <section class="bg-gray-50 py-[170px] mt-[50px]">
      <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto lg:py-0">
          <div class="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-lg xl:p-0 flex justify-center items-center">
              <div>
                <h1 class="text-xl font-bold leading-tight tracking-tight text-center text-gray-300 md:text-2xl py-[30px]">انتظر</h1>
              </div>
          </div>
      </div>
    </section>
  </div>
</template>

<script>

// import { extend } from 'vee-validate';
import axios from 'axios';


export default {
  name: 'CodeemailPage',
  data() {
    return {
    };
  },
  created(){
    console.log(this.$route.params.id)
    axios.get(`${process.env.VUE_APP_URL}/api/email/verify/doctor/${this.$route.params.id}`)
    .then((response)=>{
      if(response){
        this.$router.push('/login/doctor');
      }
    }).catch(err => {
          console.log(err)
          this.$refs.error_login.classList.remove('hidden')
          this.$refs.error_login.classList.add('block')
          // localStorage.setItem("activeUser", false);
    })
  },
}
</script>

<style  lang="scss">

</style>
