-<template>
  <div class="w-full">
    <section class="bg-gray-50 py-[120px] mt-[50px]">
      <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto lg:py-0">
          <div class="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-xl xl:p-0">
              <div class="space-y-4 md:space-y-6 py-[40px] pt-[0]">
                <div v-if="masssage_send_email == true">
                  <h1 class="text-xl font-bold leading-tight tracking-tight text-center text-gray-900 md:text-2xl py-[30px]">نرحب بانضمامكم لعائلة المنصة، ونرجو فضلاً زيارة بريدكم الالكتروني لتفعيل العضوية.</h1>
                </div>
                  <ValidationObserver v-slot="{ handleSubmit }" v-if="masssage_send_email == false">
                    <form  @submit.prevent="handleSubmit(register_user)" class="space-y-4 md:space-y-6 px-[10px] md:px-[30px] pt-[40px]" action="#">
                      <h1 class="text-xl font-bold leading-tight tracking-tight text-center text-gray-900 md:text-2xl mb-[30px]">إنشاء حساب   <span class="text-[#5599f9]">جديد</span></h1>
                        <div class="w-full grid sm:grid-cols-2 gap-[20px]">
                          <ValidationProvider name="first_name" rules="required" :custom-messages="{required: 'اكتب اسمك الاول !'}" v-slot="{ errors }">
                            <div>
                              <label for="first_name" class="block mb-2 text-sm font-medium text-gray-900 ">الاسم الاول</label>
                              <input type="text" v-model="frist_name_clint" name="first_name" id="first_name" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 " placeholder="" required="">
                              <span class="text-[red] font-bold text-[12px] m-[5px] my-[20px] block">{{ errors[0] }}</span>
                            </div>
                          </ValidationProvider>
                          <ValidationProvider name="last_name" rules="required" :custom-messages="{required: 'اكتب اسم العائلة  !'}" v-slot="{ errors }">
                            <div>
                              <label for="last_name" class="block mb-2 text-sm font-medium text-gray-900 ">اسم العائلة</label>
                              <input type="text" v-model="last_name_clint" name="last_name" id="last_name" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 " placeholder="" required="">
                              <span class="text-[red] font-bold text-[12px] m-[5px] my-[20px] block">{{ errors[0] }}</span>
                            </div>
                          </ValidationProvider>
                        </div>
                        <ValidationProvider vid="mobile" rules="required" :custom-messages="{required: 'اكتب رقم الجوال !'}" name="mobile" v-slot="{ errors }">
                          <label for="mobile" class="block mb-2 text-sm font-medium text-gray-900 ">رقم الجوال</label>
                          <VuePhoneNumberInput                               
                                v-model="mobile"
                                default-country-code="SA"
                                required
                                @update="updatePhoneNumber"
                                :only-countries="[ 'SA', 'EG', 'KW', 'AE', 'QA', 'BH', 'OM', 'IQ', 'SY', 'JO', 'LB', 'PS', 'SD', 'LY', 'MA', 'DZ', 'TN', 'YE', 'MR', 'DJ']"
                                dir='ltr' lang='en'
                                 />
                          <span class="text-[red] font-bold text-[12px] m-[5px] my-[20px] block">{{ errors[0] }}</span>
                          <span class="text-[red] font-bold text-[12px] m-[5px] my-[20px] block">{{ valid_mobile_text }}</span>
                        </ValidationProvider>
                        <ValidationProvider name="Name" rules="required|email" :custom-messages="{required: 'اكتب البريد الالكتروني !', email: 'اكتب البريد الالكتروني !'}" v-slot="{ errors }">
                          <label for="email" class="block mb-2 text-sm font-medium text-gray-900 ">بريدك الالكتروني</label>
                          <input type="email" v-model="email_clint" name="email" id="email" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 " placeholder="" required="">
                          <span class="text-[red] font-bold text-[12px] m-[5px] my-[20px] block">{{ errors[0] }}</span>
                          <h1 ref="error_login" class="hidden text-[14px] font-bold leading-tight tracking-tight text-center text-[red] mb-[30px]"> هذا البريد الالكتروني مستخدم من قبل، نرجوا استخدام بريد الكتروني آخر.</h1>
                        </ValidationProvider>
                        <ValidationProvider name="Name" rules="required|min:8|alpha_dash" :custom-messages="{required: 'اكتب كلمة السر !', min: 'لا تقل عن 8 احروف ', alpha_dash: 'لا يمكن كتابه هذة الرموز'}" v-slot="{ errors }">
                            <label for="password" class="block mb-2 text-sm font-medium text-gray-900">كلمة المرور</label>
                            <input type="password" @keyup="valid_pass" v-model="pass_clint" name="password" id="password"  class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 " required="" placeholder="يجب أن ال تقل عن 8 حروف وأن تكون خليط من حروف وأرقام ">
                            <span class="text-[red] font-bold text-[12px] m-[5px] my-[20px] block">{{ errors[0] }}</span>
                            <span class="text-[red] font-bold text-[12px] m-[5px] my-[20px] block">{{ pass_valid }}</span>
                        </ValidationProvider>
                        <div class="w-full grid sm:grid-cols-2 gap-[20px]">
                            <div class=" relative w-full sm:w-[200px] mb-[20px]">
                              <div class="relative w-full">
                                <div ref="mytoogle_gender_clint" class="cursor-pointer flex items-center justify-between border p-4 py-[10px] text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-primary-500 focus:border-primary-500  w-full" @click="toogle_gender_clint == false? toogle_gender_clint = true : toogle_gender_clint = false"><p class="pr-[35px] md:pr-[20px] capitalize">{{select_gender_clint}}</p> <img :src="require('@/image/angle-down.png')" class="w-[21px]" :class="{'-rotate-90': toogle_gender_clint}"></div>
                                  <ul class="absolute top-[103%] left-0 z-50 bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full" :class="{'block': toogle_gender_clint, hidden: !toogle_gender_clint}">
                                    <li v-for="(gender_clint, index) in gender_clint" :key="gender_clint" @click="selectOF_gender_clint(gender_clint, index)" class="item_select capitalize cursor-pointer py-[12px] px-[18px] text-[14px] flex items-center hover:bg-[#eeeeee] hover:text-black">{{gender_clint}} <span class="press">اختر</span></li>
                                  </ul>
                                </div>
                              </div>
                              <ValidationProvider name="age" rules="required" :custom-messages="{required: 'اكتب عمرك !'}" v-slot="{ errors }">
                                <div>
                                  <input type="number" v-model="age" name="age" id="age" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 " placeholder="العمر" required="">
                                  <span class="text-[red] font-bold text-[12px] m-[5px] my-[20px] block">{{ errors[0] }}</span>
                                </div>
                              </ValidationProvider>
                          </div>
                            <ValidationProvider name="terms" rules="required|" :custom-messages="{required: 'اكتب كلمة السر !'}" >
                              <div class="flex items-start mt-[20px]">
                                <div class="flex items-center h-5">
                                  <input id="terms" aria-describedby="terms" v-model="terms" type="checkbox" class="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800" required="">
                                </div>
                                <div class="ml-3 text-sm">
                                  <label for="terms" class="font-light text-gray-500 dark:text-gray-300 border-[blue]"> الضغط هنا يعني الموافقة على  <router-link to="/privacy" class="font-medium text-[#0000ffbd] hover:underline dark:text-primary-700">الخصوصية</router-link> و <router-link to="terms" class="font-medium text-[#0000ffbd] hover:underline dark:text-primary-700" href="#">اتفاقية الاستخدام</router-link></label>
                                </div>
                              </div>
                            </ValidationProvider>
                        <button type="submit" class="w-full text-white bg-[#5599f9] hover:bg-[#4b89e1] focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">إنشاء حساب جديد</button>
                        <p class="text-sm font-light text-gray-500 ">
                          هل تمتلك حساباً في المنصة؟ يمكنك <router-link to="/login" class="font-medium text-primary-600 hover:underline">تسجيل الدخول الآن </router-link>
                        </p>
                    </form>
                </ValidationObserver>
              </div>
          </div>
      </div>
    </section>
  </div>
</template>

<script>

// import {extend  } from 'vee-validate';
import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import axios from 'axios';

export default {
  name: 'RegisterPage',
  data() {
    return {
      masssage_send_email: false,
      frist_name_clint: '',
      last_name_clint: '',
      email_clint: '',
      pass_clint: '',
      mobile: '',
      age: '',
      valid_mobile:'',
      valid_mobile_text:'',
      terms:'',

      pass_valid: '',


      gender_clint: ['ذكر', 'انثي'],
      select_gender_clint: 'ذكر',
      toogle_gender_clint : false,
    };
  },
  components:{
    ValidationProvider,
    ValidationObserver,
    VuePhoneNumberInput
  },
  mounted() {

    if(this.$localStorage.token){
      this.$router.push('/')
    }

    let self = this;
    document.addEventListener('click', (e)=> {
        if (self.$refs.mytoogle_gender_clint !==undefined && self.$refs.mytoogle_gender_clint.contains(e.target)===false) {
          self.toogle_gender_clint = false;
        }
    })
  },
  methods: {
    valid_pass(){
      if(this.pass_clint.match(/[a-z]/g) !== null && this.pass_clint.match(/[0-9]/g) !== null){
        this.pass_valid = ''
      }else{
        this.pass_valid = ' كلمة المرور يجب أن لا تقل عن 8 حروف وتكون خليط من حروف وأرقام ورموز'
      }
    },
    updatePhoneNumber(data) {
      this.valid_mobile = data.isValid;
      this.mobile = data.phoneNumber;
      this.national_number = data.nationalNumber;
    },
    register_user () {
      if(this.valid_mobile && this.pass_valid == ''){
        this.valid_mobile_text = '';
        const addData = {
            first_name:  this.frist_name_clint,
            last_name:  this.last_name_clint,
            phone: this.mobile,
            email: this.email_clint,
            password: this.pass_clint,
            gender: this.select_gender_clint,
            age: this.age
        }       
        axios.post(`${process.env.VUE_APP_URL}/api/auth/register/user`, addData)
        .then(()=>{
            this.$refs.error_login.classList.remove('block')
            this.$refs.error_login.classList.add('hidden')
            this.masssage_send_email = true;
        }).catch((error) => { 
               if (error) {
                this.$refs.error_login.classList.remove('hidden')
                this.$refs.error_login.classList.add('block')       
               }  
          })
      }else{
        if(this.valid_mobile !== true){
          this.valid_mobile_text = 'الرقم غير صحيح';
        }
      }
    },
    selectOF_gender_clint(x){
      this.select_gender_clint = x;
     },
  }
}
</script>

<style  lang="scss">
 .item_select{
  position: relative;

  .press{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 20px;
    font-size: 12px;
    color: rgb(168, 168, 168);
    display: none;
  }
  &:hover{
    .press{
      display: block !important;
    }
  }
  
}
</style>
