<template>
  <div class="BookanappointmentPage ">
    <div class="BookanappointmentPage  text-center w-full max-w-[1400px] m-auto px-[10px] md:px-[60px] py-[60px] relative">
      <h1 class="absolute top-0 left-0 text-[green] font-bold text-[18px] mb-[20px]">{{ prices[1].price }} ريال</h1>
      <ValidationObserver v-slot="{ handleSubmit }"  v-show="details_form == false">
        <h1 class="text-xl font-bold leading-tight tracking-tight text-center text-gray-900 md:text-[28px] mb-[20px]">حجز موعد مع الاستشاري</h1>
      <p class="text-[14px] text-gray-700 mb-[30px]">خدمة تقدمها منصة كلينيكير لحجز موعد مع الاستشاري لمناقشة الرأي الطبي البديل والخيارات المتاحة والفحوصات المطلوبة للوصول للتشخيص الدقيق وإعطاء رأي طبي آخر</p>
        <form  @submit.prevent="handleSubmit(details_form1)" class="space-y-4 md:space-y-6 px-[10px] md:px-[30px] pt-[40px] text-start" action="#">
            <div class=" relative w-full">
                <div class="relative w-full">
                  <label for="title" class="block mb-2 text-sm font-medium text-gray-900 ">مجال الاستشارة</label>
                  <div ref="mytoogle_specialties" class="cursor-pointer flex items-center justify-between border p-4 py-[10px] text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-primary-500 focus:border-primary-500 w-full" @click="toogle_specialties == false? toogle_specialties = true : toogle_specialties = false"><p class="pr-[35px] md:pr-[20px] capitalize">{{select_specialties}}</p> <img :src="require('@/image/angle-down.png')" class="w-[21px]" :class="{'-rotate-90': toogle_specialties}"></div>
                  <ul class="absolute top-[103%] left-0 z-50 bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full" :class="{'block': toogle_specialties, hidden: !toogle_specialties}">
                    <li v-for="(specialties, index) in specialtiess" :key="specialties" @click="selectOF_specialties(specialties, index)" class="item_select capitalize cursor-pointer py-[12px] px-[18px] text-[14px] flex items-center hover:bg-[#eeeeee] hover:text-black">{{specialties}} <span class="press">اختر</span></li>
                  </ul>
                </div>
            </div>
            <ValidationProvider name="details_problem" rules="required" :custom-messages="{required: ' تفاصيل المشكة الطبية !'}" v-slot="{ errors }">
              <div class="my-[20px]">
                <label for="details_problem" class="block mb-2 text-sm font-medium text-gray-900 ">اكتب تفاصيل المشكة </label>
                <input type="text" v-model="details_problem_clint" name="details_problem" id="details_problem" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 " placeholder="" required="">
                <span class="text-[red] font-bold text-[12px] m-[5px] my-[20px] block">{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
              <h1 class="block mb-2 text-sm font-medium text-gray-900 mt-[30px]">طبيعة اللقاء</h1>
              <div class="w-full flex flex-wrap justify-start items-center gap-[20px]">
                <div class="flex items-center">
                    <input id="حضوري" v-model="type_meeting" type="radio" value="حضوري" name="حضوري" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500">
                    <label for="حضوري" class="ms-2 text-sm font-medium text-gray-900">حضوري</label>
                </div>
                <div class="flex items-center">
                    <input id="أونلاين" v-model="type_meeting" type="radio" value="أونلاين" name="أونلاين" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 ">
                    <label for="أونلاين" class="ms-2 text-sm font-medium text-gray-900">أونلاين</label>
                </div>
              </div>
              <div class=" relative w-full mb-[30px]">
                <span ref='error_hospitall' class="hidden text-[red] font-bold text-[12px] m-[5px] my-[20px] block">اختر المستشفي</span>
                <div class="relative w-full">
                  <label for="title" class="block mb-2 text-sm font-medium text-gray-900 ">االستشارة الحضورية </label>
                  <div ref="mytoogle_hospitals" class="cursor-pointer flex items-center justify-between border p-4 py-[10px] text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-primary-500 focus:border-primary-500 w-full" @click="toogle_hospitals == false? toogle_hospitals = true : toogle_hospitals = false"><p class="pr-[35px] md:pr-[20px] capitalize">{{select_hospitals}}</p> <img :src="require('@/image/angle-down.png')" class="w-[21px]" :class="{'-rotate-90': toogle_hospitals}"></div>
                  <ul class="absolute top-[103%] left-0 z-50 bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full" :class="{'block': toogle_hospitals, hidden: !toogle_hospitals}">
                    <li v-for="(hospitals, index) in hospitalss" :key="index" @click="selectOF_hospitals(hospitals.name, hospitals.id)" class="item_select capitalize cursor-pointer py-[12px] px-[18px] text-[14px] flex items-center hover:bg-[#eeeeee] hover:text-black">{{ hospitals.name + '   - ' +  hospitals.address}} <span class="press">اختر</span></li>
                  </ul>
                </div>
              </div>
              <div class=" relative w-full mb-[30px]">
                <span ref='error_hospitall' class="hidden text-[red] font-bold text-[12px] m-[5px] my-[20px] block">اختر الدكتور</span>
                <div class="relative w-full">
                  <label for="title" class="block mb-2 text-sm font-medium text-gray-900 ">الستشارة عن بعد</label>
                  <div ref="mytoogle_doctors" class="cursor-pointer flex items-center justify-between border p-4 py-[10px] text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-primary-500 focus:border-primary-500 w-full" @click="toogle_doctors == false? toogle_doctors = true : toogle_doctors = false"><p class="pr-[35px] md:pr-[20px] capitalize">{{select_doctors}}</p> <img :src="require('@/image/angle-down.png')" class="w-[21px]" :class="{'-rotate-90': toogle_doctors}"></div>
                  <ul class="absolute top-[103%] left-0 z-50 bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full" :class="{'block': toogle_doctors, hidden: !toogle_doctors}">
                    <li v-for="(doctor, index) in doctors" :key="index" @click="selectOF_doctors(doctor)" class="item_select capitalize cursor-pointer py-[12px] px-[18px] text-[14px] flex items-center hover:bg-[#eeeeee] hover:text-black">{{ doctor }} <span class="press">اختر</span></li>
                  </ul>
                </div>
              </div>
            <div class="w-full md:w-[100%] relative mt-[30px]">
                  <div id="conn-input-file" class="w-full p-[20px]">
                    <div class="flex flex-col gap-[10px] justify-center items-start">
                      <span v-for="(upload_file, index) in upload_file_clint" :key="index"> {{ index + ' - '+ upload_file.name}}</span>
                      <span v-if="upload_file_clint.length == 0">تحميل التقارير الطبية والفحوصات</span>
                    </div>
                    <input type="file" ref="input_file" @change="handleFileChange" name="upload_file" id="add-music-file" multiple accept="image/*,.pdf" >
                  </div>
              </div>
            <button type="submit" class="w-full text-white bg-[#5599f9] hover:bg-[#4b89e1] focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">إرسال</button>
        </form>
    </ValidationObserver>
    <ValidationObserver v-slot="{ handleSubmit }" v-if="details_form == true">
      <h1 class="text-xl font-bold leading-tight tracking-tight text-center text-gray-900 md:text-[28px] mb-[20px]">الدفع</h1>
      <p class="text-[14px] text-gray-700 mb-[30px]"> نرجوا تحويل قيمة الاستشارة للحساب البنكي التالي، ثم إرفاق صورة من التحويل لعتماد الطلب</p>
        <div class="w-full text-start p-[30px_30px]">
          <h1 class="text-[18px] font-[700] mb-[20px] text-[#272727]" v-for="(bank, index) in banks" :key="index">عن طريق {{bank.bank}} : <b>{{ bank.number }}</b></h1>
        </div>
        <form  @submit.prevent="handleSubmit(onSubmit)" class="space-y-4 md:space-y-6 px-[10px] md:px-[30px] pt-[20px] text-start" action="#">
            <ValidationProvider rules="required" ref="provider2" name="upload_file" :custom-messages="{required: ' ارفع التحويل !'}" v-slot="{ errors }">
              <div class="w-full md:w-[100%] relative mt-[10px]">
                  <div id="conn-input-file" class="w-full ">
                    <span> {{ upload_file_clint_transfers == false? 'إرفاق التحويل البنكي' : upload_file_clint_transfers.name}}</span>
                    <input type="file" ref="input_file2" @change="handleFileChange2" name="upload_file" id="add-music-file" accept="image/*,.pdf">
                    <span class="text-[red] font-bold text-[12px] m-[5px] my-[20px] block">{{ errors[0] }}</span>
                  </div>
              </div>
            </ValidationProvider>
            <button type="submit" :disabled='isDisabled' class="w-full text-white bg-[#5599f9] hover:bg-[#4b89e1] focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">اعتماد الطلب</button>
        </form>
    </ValidationObserver>
    </div>
  </div>
</template>

<script>

import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full';
import axios from 'axios';

export default {
  name: 'BookanappointmentPage',
  data() {
    return {
      upload_file_clint_transfers: false,
      details_problem_clint: '',
      details_form: false,

      notes_clint: '',
      type_meeting: '',

      prices: [
        {price: 0},
        {price: 0},
        {price: 0}
      ],
      banks: '',

      files: [],
      upload_file_clint: [],

      isDisabled: false,

      specialtiess: [],
      select_specialties: '',
      toogle_specialties : false,

      hospitalss: [],
      select_hospitals: 'المستشفيات والمراكز الطبية  ',
      hospitalss_id: false,
      toogle_hospitals : false,

      doctors: [],
      select_doctors: '',
      toogle_doctors : false,
    };
  },
  created(){
    axios.get(`${process.env.VUE_APP_URL}/api/get_prices`)
    .then((response) => { 
        this.prices = response.data.prices;
        console.log(this.prices)
    }).catch(function (error) {
        console.log(error.message)
    });
    axios.get(`${process.env.VUE_APP_URL}/api/get_banks`)
    .then((response) => { 
        this.banks = response.data.numbers;
        console.log(this.banks)
    }).catch(function (error) {
        console.log(error.message)
    });
    axios.get(`${process.env.VUE_APP_URL}/api/report/create`)
    .then((response) => { 
        response.data.professions.forEach((value) => {
            this.specialtiess.push(value.name);
        });
        this.select_specialties = response.data.professions[0].name
    }).catch(function (error) {
        console.log(error.message)
    });
    axios.get(`${process.env.VUE_APP_URL}/api/reservation/create`)
    .then((response) => { 
      console.log(response.data)
        response.data.hospitals.forEach((value) => {
            this.hospitalss.push(value);
        });
    }).catch(function (error) {
        console.log(error.message)
    });
    axios.get(`${process.env.VUE_APP_URL}/api/doctor/home`)
      .then((response) => { 
        console.log(response.data)
        response.data.doctors.forEach((value) => {
            this.doctors.push(`${value.first_name} ${value.last_name}`);
        });
        this.select_doctors = `${response.data.doctors[0].first_name} ${response.data.doctors[0].last_name}`
      }).catch(function (error) {
          console.log(error.message)
      }); 
  },
  components:{
    ValidationProvider,
    ValidationObserver
  },
  mounted() {
  let self = this;
  document.addEventListener('click', (e)=> {
      if (self.$refs.mytoogle_specialties !==undefined && self.$refs.mytoogle_specialties.contains(e.target)===false) {
        self.toogle_specialties = false;
      }
      if (self.$refs.mytoogle_hospitals !==undefined && self.$refs.mytoogle_hospitals.contains(e.target)===false) {
        self.toogle_hospitals = false;
      }
      if (self.$refs.mytoogle_doctors !==undefined && self.$refs.mytoogle_doctors.contains(e.target)===false) {
        self.toogle_doctors = false;
      }
  })
  },
  methods: {
    details_form1 () {
      this.details_form = true;
    },
    onSubmit(){
      this.isDisabled = true
        const addData = {
            notes: this.details_problem_clint,
            profession: this.select_specialties,
            transaction: this.upload_file_clint_transfers,
            price : 300,
            file: this.upload_file_clint,
        }     
        const headers = { 
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${this.$localStorage.token}`
        };
    
        axios.post(`${process.env.VUE_APP_URL}/api/create_meeting`, addData, { headers })
        .then(()=>{
            this.$toast.success(" تم استالم الطلب وجاري اتخاذالازم،وستصلكم رسالة عند اعتمادالطلب. شكرا لثقتكم باملنصةونتمنى لكم الشفاءالعاجل", {
              position: "top-right",
              timeout: 1000,
              pauseOnFocusLoss: true,
              hideProgressBar: true,
              icon: false,
              rtl: true
            });
            this.$router.push('/My-all-servicesPage');
       
        });
    },
    selectOF_specialties(x){
      this.select_specialties = x;
     },
     selectOF_doctors(x){
      this.select_doctors = x;
     },
     selectOF_hospitals(x,y){
      this.select_hospitals = x;
      this.hospitalss_id = y
     },
    async handleFileChange(event) {
      const file = event.target.files;
      this.upload_file_clint = file
    },
    async handleFileChange2(e) {
      const { valid } = await this.$refs.provider2.validate(e);
      if (valid) {
        this.upload_file_clint_transfers = this.$refs.input_file2.files[0]
      }else{
        this.upload_file_clint_transfers = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
 .item_select{
  position: relative;

  .press{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 20px;
    font-size: 12px;
    color: rgb(168, 168, 168);
    display: none;
  }
  &:hover{
    .press{
      display: block !important;
    }
  }
}

#conn-input-file {
  width: 100%;
  background: linear-gradient(90deg, rgb(2, 0, 36) 0%, #a5003dbf 100%, rgba(9, 9, 121, 0.2973564426) 100%);
  color: white;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  border-radius: 4px;
  transition: .5s;

  &:hover{
    background: linear-gradient(90deg, rgb(2, 0, 36) 0%, #410018bf 100%, rgba(9, 9, 121, 0.2973564426) 100%);
  }

  input {
      width: 100%;
      height: 100%;
      opacity: 0;
      z-index: 999999;
      position: absolute;
      top: 0;
      left: 0;
      cursor: pointer;
  }
}

</style> 