<template>
  <div class="WhycliniccarePage">
    <header class="w-full px-[30px] md:px-[80px] pt-[130px] pb-[80px] text-white relative after:absolute after:top-0 after:left-0 after:w-full after:h-full after:bg-[rgba(0,0,0,0.73)]">
      <img :src="`${url}/${blog.image}`" class="absolute top-0 left-0 w-full h-full object-cover" alt="">
      <div class="z-[2] relative text-center">
        <h1 class="text-[10vw] md:text-[50px] font-[700] mb-[20px]">{{ blog.title }}</h1>
      </div>
    </header>
    <div class="PrivacyPage text-center w-full m-auto px-[30px] md:px-[100px] py-[20px]">
      <div class="max-w-2xl mx-auto bg-white p-8 rounded shadow-md">
          <div
            v-html="blog.body"
            id="answer_massage"
            class="text-[#212529] text-start"
          ></div>
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios';

export default {
  name: 'WhycliniccarePage',
  data() {
    return {
      blog: [],
      url: process.env.VUE_APP_URL,
    };
  },
  created(){
    axios.get(`${process.env.VUE_APP_URL}/api/get_blogs/${this.$route.query.q}`)
    .then((response) => {  
        this.blog = response.data.blog;
    }).catch(function (error) {
        console.log(error.message)
    });
  },
}
</script>

<style scoped lang="scss">

</style> 